<template>
  <v-card>
    <DataTablePagination
      :page="page"
      :total-items="totalItems"
      :headers="headers"
      :items="classLevels"
      title="Tingkatan Kelas"
      subtitle="Halaman untuk menambahkan tingkatan kelas pada sekolah"
      add-btn="Tambah Data"
      :total-pages.sync="totalPages"
      @add-item="showFormAdd"
    >
    </DataTablePagination>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      @close="modalDialog = false"
    >
      <template
        v-if="dialog == 'add'"
        v-slot:header
      >
        TAMBAH TINGKATAN KELAS
      </template>
      <template
        v-else
        v-slot:header
      >
        UPDATE TINGKATAN KELAS
      </template>
      <template v-slot:body>
        <v-alert color="primary">
          <span class="white--text">Isikan tingkatan kelas sesuai dengan jenjang pendidikan sekolah anda.
            Misalnya : SMA/SMK : X, XI, XII, 10, 11, 12</span>
        </v-alert>
        <div>
          <v-text-field
            label="Tingkatan Kelas"
            type="text"
            outlined
            dense
          >
          </v-text-field>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn
          v-if="dialog == 'add'"
          color="primary"
          large
        >
          Tambah
        </v-btn>
        <v-btn
          v-if="dialog == 'edit'"
          color="primary"
          large
        >
          Update
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
  </v-card>
</template>

<script>
import DataTablePagination from '../components/DataTablePagination.vue'
import ModalDialog from '../components/ModalDialog.vue'

export default {
  name: 'ReferencyClassLevel',
  components: {
    DataTablePagination,
    ModalDialog,
  },
  data() {
    return {
      page: 1,
      totalPages: 0,
      totalItems: 10,
      modalDialog: false,
      width: '600',
      dialog: null,
      headers: [
        { text: 'No', value: 'no' },
        { text: 'Tingkatan Kelas', value: 'title' },
        { text: 'Aksi', value: 'actions' },
      ],
      classLevels: [
        { no: '#', title: 'VI' },
        { no: '#', title: 'V' },
        { no: '#', title: 'IV' },
        { no: '#', title: 'III' },
        { no: '#', title: 'II' },
        { no: '#', title: 'I' },
      ],
    }
  },
  methods: {
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
    },
    showFormEdit() {
      this.dialog = 'edit'
      this.modalDialog = true
    },
  },
}
</script>

<style>
</style>
